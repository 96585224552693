.works-title {
   font-family: DM Sans;
   font-style: normal;
   font-weight: 500;
   font-size: 24px;
   line-height: 25px;
   color: #2e3192;
}

.works-desc {
   font-family: Nunito;
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 16px;
   color: #868686;
}

.works-section {
   flex-basis: 30%;
}

.last-set-criteria {
   margin-top: 5rem;
}

@media (max-width: 991px) {
   .last-set-criteria {
      margin-top: 0;
   }
   .works-section {
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
   }

   /* .works-section > div {
      flex-basis: 100%;
      width: 100%;
   } */
}

.earth-space {
   width: 80%;
   margin: 0 auto;
   margin-top: 4rem;
   background-image: url("../../assets/images/earth.svg");
   min-height: 510px;
   background-position: center;
   background-size: contain;
   /* opacity: 0.1; */
   background-repeat: no-repeat;
}

.capacity-score {
   font-family: Nunito;
   font-style: normal;
   font-weight: bold;
   font-size: 4rem;
   line-height: 5.8rem;
   color: #00adef;
   margin-bottom: 0;
   text-align: center;
}

.capacity-label {
   font-family: DM Sans;
   font-style: normal;
   font-weight: 500;
   font-size: 1.7rem;
   line-height: 2.3rem;
   color: #ffffff;
   margin-bottom: 0;
   text-align: center;
}

.lower-list-capacity {
   width: 80%;
   margin: 0 auto;
   margin-top: 5rem;
}

@media (max-width: 991px) {
   .lower-list-capacity {
      width: 100%;
      margin-top: 0;
   }
}

.home-blog-list {
   column-gap: 40px;
   width: 100%;
   overflow: auto;
   margin-top: 50px;
}

.blog-sec-btn {
   background-color: #2e3192 !important;
}

.position-blog-btn {
   position: absolute;
   top: 100px;
   right: 7%;
}

@media (max-width: 716px) {
   .position-blog-btn {
      position: static;
   }
}

.featured-investor {
   padding: 81px 7%;
   row-gap: 20px;
}

.featured-investor div {
   column-gap: 3rem;
   row-gap: 15px;
}

.featured-investor h2 {
   font-family: Nunito;
   font-style: normal;
   font-weight: bold;
   font-size: 3rem;
   line-height: 4.06rem;
   color: #263238;
}

.subscribe-news {
   background: linear-gradient(90deg, #242679 -3.44%, #1d2147 86.67%);
   padding: 81px 7%;
   row-gap: 15px;
}

.subscribe-news h2 {
   font-family: Nunito;
   font-style: normal;
   font-weight: bold;
   font-size: 3rem;
   line-height: 4.06rem;
   color: #ffffff;
   flex-basis: 40%;
}

.subscribe-news div {
   column-gap: 2rem;
   row-gap: 15px;
   flex-basis: 50%;
}

.subscribe-news input {
   flex-basis: 30%;
}

@media (max-width: 1376px) {
   .subscribe-news input {
      flex-basis: 50%;
   }
}

@media (max-width: 1024px) {
   .subscribe-news h2 {
      flex-basis: 80%;
   }

   .subscribe-news div {
      flex-basis: 100%;
   }

   .subscribe-news input {
      flex-basis: 50%;
   }
}

@media (max-width: 600px) {
   .subscribe-news input {
      flex-basis: 100%;
   }

   .subscribe-news h2 {
      flex-basis: 100%;
   }
}

.partner-section {
   min-height: 300px;
}

@media (max-width: 1024px) {
   .partner-section {
      min-height: 350px;
   }
}

.section-padding-top {
   padding-top: 130px;
}

.investor-connect-card .more-float {
   right: 0px;
}
