.blog-card {
   border-radius: 24px;
   min-height: 500px;
   position: relative;
   padding: 60px 45px;
   color: white;
   background-size: cover !important;
   background-position: center !important;
   background-repeat: no-repeat !important;
   cursor: pointer;
}

.blog-card .date {
   background: #f5f5f5;
   border-radius: 0px 24px 0px 0px;
   width: fit-content;
   padding: 19px 19px;
   position: absolute;
   top: 0;
   right: 0;
   color: #2e3192;
   text-align: center;
}

.blog-card .date .day {
   font-family: Nunito;
   font-style: normal;
   font-weight: bold;
   font-size: 24px;
   line-height: 18px;
   color: #2e3192;
}

.blog-card .date .month {
   font-family: Nunito;
   font-style: normal;
   font-weight: bold;
   font-size: 18px;
   line-height: 18px;
   /* or 75% */

   display: flex;
   align-items: center;
   text-align: center;

   /* 900 */

   color: #2e3192;
}

.blog-card .title {
   font-family: Nunito;
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 24px;
   color: #ffffff;
   mix-blend-mode: normal;
   opacity: 0.5;
}

.blog-card .subtitle {
   font-family: DM Sans;
   font-style: normal;
   font-weight: bold;
   font-size: 24px;
   line-height: 32px;
   color: #ffffff;
}

@media (max-width: 768px) {
   .blog-card {
      min-width: 280px !important;
   }
}
