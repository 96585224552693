.kv-tab-active {
   padding: 1.25rem;
   background: #00adef;
   box-shadow: 0px 4px 16px rgba(50, 171, 226, 0.32);
   border-radius: 8px;
   font-family: DM Sans;
   font-style: normal;
   font-weight: 500;
   font-size: 1rem;
   line-height: 1.6rem;
   color: #ffffff;
   min-width: 267px;
   text-align: center;
}

.kv-tab {
   padding: 1.25rem;
   border: 1px solid #2e3192;
   border-radius: 8px;
   background-color: transparent;
   font-family: DM Sans;
   font-style: normal;
   font-weight: 500;
   font-size: 1rem;
   line-height: 1.6rem;
   color: #2e3192;
   min-width: 267px;
   text-align: center;
}

.block-tab,
.dash-tab {
   display: flex;
   align-items: center;
   column-gap: 1rem;
   list-style: none;
   padding: 0;
}

.kv-dash,
.kv-dash-active {
   font-family: DM Sans;
   font-style: normal;
   font-weight: 500;
   font-size: 1.2rem;
   line-height: 1.4rem;
   color: rgba(255, 255, 255, 0.8);
   padding-bottom: 1rem;
}

.kv-dash-active {
   color: #00adef;
   border-bottom: 4px solid #00adef;
}
