.partner-slide {
   background: #2e3192;
   border-radius: 24px;
   min-height: 150px;
   padding: 15px 5%;
   position: absolute;
   bottom: -100px;
   z-index: 10;
   width: 86%;
}

.partner-slide .slick-slider {
   display: flex;
   align-items: center;
   min-height: inherit;
   column-gap: 40px;
}

.partner-slide .slick-arrow {
   width: 48px;
   min-width: 48px;
   height: 48px;
   border-radius: 50%;
   background: rgba(255, 255, 255, 0.8);
   border: none;
   overflow: hidden;
   display: flex !important;
   align-items: center;
   justify-content: center;
   color: #00adef;
   cursor: pointer;
}

.partner-slide .slick-arrow > span {
   font-size: 20px !important;
   font-weight: 800;
}

.partner-slide .slick-arrow:focus {
   outline: none;
   background-color: #00adef !important;
   color: #ffffff !important;
}

.partner-slide .slick-track {
   display: flex;
   align-items: center;
   justify-content: center;
}

.partner-slide .slick-slide > div > div {
   display: flex !important;
   justify-content: center;
}

/* .slide-arrow {
   width: 48px;
   min-width: 48px;
   height: 48px;
   border-radius: 50%;
   background: rgba(255, 255, 255, 0.8);
   display: flex;
   align-items: center;
   justify-content: center;
   color: #00adef;
}

.slick-arrow p {
   width: fit-content;
} */

/* @media (max-width: 1024px) {
   .partner-slide {
      min-height: 250px; 
   }
} */

/* @media (max-width: 768px) {
   .partner-slide {
      min-height: 250px;
   }
} */

.partner-slide img {
   width: 120px;
}
